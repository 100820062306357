import "./App.css";
import Router from "./Router";
import { recaptchaKey } from "./Global";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import bg from "./Images/background.jpg";
import bgW from "./Images/background-wide.jpg";
import { useEffect, useState } from "react";

function App() {
    const [background, setBackground] = useState("");

    useEffect(() => {
        if (window.innerWidth > window.innerHeight) {
            setBackground(`url(${bgW})`)
        } else {
            setBackground(`url(${bg})`)
        }

        function win() {
            if (window.innerWidth > window.innerHeight) {
                setBackground(`url(${bgW})`)
            } else {
                setBackground(`url(${bg})`)
            }
        }

        window.addEventListener("resize", win)

        return () => {
            window.removeEventListener("resize", win)
        }
    }, []);

    return (
        <GoogleReCaptchaProvider reCaptchaKey={recaptchaKey}>
            <div className="App d-flex flex-column min-vh-100 text-light" style={{ backgroundImage: background }}>
                <div id="bg-filter" />
                <Router />
            </div>
        </GoogleReCaptchaProvider>
    );
}

export default App;
