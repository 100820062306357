import React, { useEffect, useState } from "react";
import tiznado from "../../Images/tiznado.png";
import { products } from "../../Images/Products/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const HomeComponentv2 = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [images, setImages] = useState();

    useEffect(() => {
        const productsImg = [...products];

        productsImg.sort(() => Math.random() - 0.5);
        productsImg.slice(0, 3);

        const imagesArr = [productsImg[0][0], productsImg[1][0], productsImg[2][0]];
        setImages(imagesArr);
    }, []);

    return (
        <>
            <Helmet>
                <title>Tiznado | Piezas de autor</title>
                <meta
                    name="description"
                    content="Explora el arte en madera único de Tiznado. Descubre piezas de autor que fusionan la belleza natural con la creatividad. ¡Transforma tu espacio hoy!"
                />
                <link rel="canonical" href="https://tiznado.uy" />
            </Helmet>
            <main id="home" className=" mt-3">
                <div className="position-absolute top-0 w-100 h-100" style={{ paddingTop: "15vh" }}>
                    <div className="w-100 text-center">
                        <img
                            src={tiznado}
                            alt="tiznado"
                            className="d-block mx-auto mb-2 w-100"
                        />
                        <h2 id="subtitle">PIEZAS DE AUTOR</h2>
                    </div>
                </div>
            </main>
        </>
    );
};

export default HomeComponentv2;
