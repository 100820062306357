import React, { useEffect, useState } from "react";
import { products } from "../../Images/Products/images";
import Carousel from "react-bootstrap/Carousel";
import { Helmet } from "react-helmet";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faX } from "@fortawesome/free-solid-svg-icons";

const DesignsComponent = () => {
    const [show, setShow] = useState(false);
    const [product, setProduct] = useState();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const handleShow = (index) => {
        setProduct(index);
        setShow(true);
    };

    const handleClose = () => {
        setProduct(undefined);
        setShow(false);
    };

    return (
        <>
            <Helmet>
                <title>Nuestros diseños | Tiznado | Piezas de autor</title>
                <meta
                    name="description"
                    content="Descubre la excelencia artesanal de Tiznado en 'Nuestros Diseños': piezas únicas de autor en madera que elevan tu espacio."
                />
                <link rel="canonical" href="https://tiznado.uy/disenos" />
            </Helmet>
            <main id="designs">
                <div className="container-fluid my-3">
                    <h1 className="text-center my-5">Nuestros diseños</h1>
                    <div className="row g-3 justify-content-center">
                        {products.map((product, i) => (
                            <div
                                className="col-md-3 col-6 d-flex align-items-center justify-content-center"
                                key={i}
                            >
                                <button
                                    onClick={() => handleShow(i)}
                                    className="bg-light shadow rounded-3 p-0 h-100 d-flex align-items-center border position-relative"
                                >
                                    <img
                                        src={product.images[0]}
                                        alt=""
                                        className="piece img-fluid rounded-3 p-1"
                                    />
                                </button>
                            </div>
                        ))}
                    </div>
                </div>
            </main>
            <Modal show={show} onHide={handleClose} centered size="lg">
                <Modal.Body className="position-relative">
                    {products[product] && (
                        <>
                            <div className="position-absolute w-100" style={{ bottom: "100%", left: "0" }}>
                                <h3 className="text-light fs-1 fw-bold text-center">{products[product].name}</h3>
                            </div>
                            <Carousel>
                                {products[product].images.map((image, i) => (
                                    <Carousel.Item key={i}>
                                        <img src={image} alt={i + 1} className="img-fluid" />
                                    </Carousel.Item>
                                ))}
                            </Carousel>
                            <div className="position-absolute w-100" style={{ top: "101%", left: "0" }}>
                                <p className="text-light fw-bold text-center">Medidas: {products[product].size}</p>
                            </div>
                        </>
                    )}
                    <button
                        onClick={handleClose}
                        className="btn btn-outline-light position-absolute top-0 end-0 m-4"
                        style={{ zIndex: "2000" }}
                    >
                        <FontAwesomeIcon icon={faX} />
                    </button>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default DesignsComponent;
