import React, { useEffect } from "react";
import image from "../../Images/tiznado.png";
import { Helmet } from "react-helmet";

const AboutComponent = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>Sobre Nosotros | Tiznado | Piezas de autor</title>
                <meta
                    name="description"
                    content="Descubre la historia y pasión detrás de Tiznado en 'Sobre Nosotros'. Nuestra dedicación a la artesanía y visión creativa en piezas de autor excepcionales."
                />
                <link rel="canonical" href="https://tiznado.uy/sobre-nosotros" />
            </Helmet>
            <main id="about">
                <div className="container-fluid my-3">
                    <div className="row justify-content-center">
                        <div className="col-lg-5 col-md-6 d-flex align-items-center justify-content-center border-end px-5 py-4">
                            <div className="text-center">
                                <h1 className="mb-4">Sobre Nosotros</h1>
                                <h5 className="mb-3">¿Qué nos motiva a levantarnos cada día?</h5>
                                <p>
                                    Bajo esta premisa nos embarcamos en Tiznado. Enfocados en una simplicidad
                                    atemporal, descubriendo belleza en la imperfección. Compartiendo diseños
                                    que nos llenen, brindando en cada pieza una cálida elegancia que contagie
                                    la escencia de nuestra filosofía.
                                </p>
                                <p>
                                    Alineados con procesos amigables con el medioambiente, apostamos a la
                                    reutilización y reciclaje de materiales y a la pigmentación natural
                                    comprometidos con la sustentabilidad, cuidando cada aspecto del entorno
                                    circundante para que cada pieza cuente una historia.
                                </p>
                                <h2 className="fs-1 mt-5 mb-0">BIO</h2>
                                <h2 className="mb-4">
                                    Rodrigo Ramos
                                    <br />
                                    (Rocha, 1979)
                                </h2>
                                <h5 className="mb-3">
                                    Vive y trabaja en la Ciudad de Chuy, frontera con Brasil
                                </h5>
                                <p>
                                    Su incipiente trabajo escultórico viene precedido de una larga búsqueda
                                    artística. Con estudios cinematográficos, incursionó en diversas áreas
                                    como realización de videoclips musicales, cortometrajes, críticas de cine
                                    y esculturas en espacios públicos (Tiznados). Con ENIGMA da comienzo a una
                                    nueva etapa creativa que es precursora de TIZNADO dónde sus estudios y
                                    experiencia lo guían por diferentes formas, patrones y texturas, siempre
                                    enfocado en la percepción visual del espectador.
                                </p>
                                <p>
                                    Nacido entre las costas de Rocha y los palmares, sus obras evocan la
                                    temática naturista, apoyado por la materia prima nativa .
                                </p>
                                <p>
                                    Aún en busca de un lenguaje propio, sus líneas limpias y las formas
                                    naturales, juegan con la luz y los contrastes, creando a mano piezas de
                                    diseño tangibles con terminaciones de alto standard.
                                </p>
                                <p>
                                    Tanto el método de trabajo como su filosofía están apoyados en la
                                    tradición oriental.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default AboutComponent;
