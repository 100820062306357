import prod01img01 from "../../Images/Products/prod01img01.jpg";
import prod01img02 from "../../Images/Products/prod01img02.jpg";
import prod01img03 from "../../Images/Products/prod01img03.jpg";
import prod01img04 from "../../Images/Products/prod01img04.jpg";
import prod01img05 from "../../Images/Products/prod01img05.jpg";
import prod02img01 from "../../Images/Products/prod02img01.jpg";
import prod02img02 from "../../Images/Products/prod02img02.jpg";
import prod02img03 from "../../Images/Products/prod02img03.jpg";
import prod02img04 from "../../Images/Products/prod02img04.jpg";
import prod03img01 from "../../Images/Products/prod03img01.jpg";
import prod03img02 from "../../Images/Products/prod03img02.jpg";
import prod03img03 from "../../Images/Products/prod03img03.jpg";
import prod03img04 from "../../Images/Products/prod03img04.jpg";
import prod04img01 from "../../Images/Products/prod04img01.jpg";
import prod04img02 from "../../Images/Products/prod04img02.jpg";
import prod04img03 from "../../Images/Products/prod04img03.jpg";
import prod05img01 from "../../Images/Products/prod05img01.jpg";
import prod06img01 from "../../Images/Products/prod06img01.jpg";
import prod06img02 from "../../Images/Products/prod06img02.jpg";
import prod06img03 from "../../Images/Products/prod06img03.jpg";
import prod07img01 from "../../Images/Products/prod07img01.jpg";
import prod08img01 from "../../Images/Products/prod08img01.jpg";
import prod09img01 from "../../Images/Products/prod09img01.jpg";
import prod09img02 from "../../Images/Products/prod09img02.jpg";
import prod09img03 from "../../Images/Products/prod09img03.jpg";
import prod09img04 from "../../Images/Products/prod09img04.jpg";
import prod10img01 from "../../Images/Products/prod10img01.jpg";
import prod10img02 from "../../Images/Products/prod10img02.jpg";
import prod10img03 from "../../Images/Products/prod10img03.jpg";
import prod10img04 from "../../Images/Products/prod10img04.jpg";
import prod10img05 from "../../Images/Products/prod10img05.jpg";
import prod11img01 from "../../Images/Products/prod11img01.jpg";
import prod11img02 from "../../Images/Products/prod11img02.jpg";
import prod11img03 from "../../Images/Products/prod11img03.jpg";
import prod11img04 from "../../Images/Products/prod11img04.jpg";
import prod12img01 from "../../Images/Products/prod12img01.jpg";
import prod12img02 from "../../Images/Products/prod12img02.jpg";
import prod12img03 from "../../Images/Products/prod12img03.jpg";
import prod13img01 from "../../Images/Products/prod13img01.jpg";
import prod13img02 from "../../Images/Products/prod13img02.jpg";
import prod13img03 from "../../Images/Products/prod13img03.jpg";
import prod13img04 from "../../Images/Products/prod13img04.jpg";
import prod14img01 from "../../Images/Products/prod14img01.jpg";
import prod14img02 from "../../Images/Products/prod14img02.jpg";
import prod14img03 from "../../Images/Products/prod14img03.jpg";
import prod14img04 from "../../Images/Products/prod14img04.jpg";
import prod14img05 from "../../Images/Products/prod14img05.jpg";
import prod15img01 from "../../Images/Products/prod15img01.jpg";
import prod15img02 from "../../Images/Products/prod15img02.jpg";
import prod15img03 from "../../Images/Products/prod15img03.jpg";
import prod16img01 from "../../Images/Products/prod16img01.jpg";
import prod16img02 from "../../Images/Products/prod16img02.jpg";
import prod16img03 from "../../Images/Products/prod16img03.jpg";
import prod17img01 from "../../Images/Products/prod17img01.jpg";
import prod17img02 from "../../Images/Products/prod17img02.jpg";
import prod17img03 from "../../Images/Products/prod17img03.jpg";
import prod18img01 from "../../Images/Products/prod18img01.jpg";
import prod18img02 from "../../Images/Products/prod18img02.jpg";
import prod18img03 from "../../Images/Products/prod18img03.jpg";
import prod18img04 from "../../Images/Products/prod18img04.jpg";

export const products = [
    { name: "Stargate", size: "58cms", images: [prod01img01, prod01img02, prod01img03, prod01img04, prod01img05] },
    { name: "Wall Art", size: "48 x 50cms", images: [prod02img01, prod02img02, prod02img03, prod02img04] },
    { name: "Erosión", size: "56cms", images: [prod03img01, prod03img02, prod03img03, prod03img04] },
    { name: "Shai Hulud", size: "52cms", images: [prod04img01, prod04img02, prod04img03] },
    { name: "Shai Hulud (natural)", size: "52cms", images: [prod12img01, prod12img02, prod12img03] },
    { name: "Moon", size: "-", images: [prod09img01, prod09img02, prod09img03, prod09img04] },
    { name: "Flor de Mar", size: "56 x 60cms", images: [prod05img01] },
    { name: "Flor de Mar", size: "56 x 60cms", images: [prod06img01, prod06img02, prod06img03] },
    { name: "Flor de Mar", size: "56 x 60cms", images: [prod07img01] },
    { name: "Flor de Mar", size: "56 x 60cms", images: [prod08img01] },
    { name: "Sinapsis", size: "-", images: [prod10img01, prod10img02, prod10img03, prod10img04, prod10img05] },
    { name: "Vidriagón", size: "-", images: [prod11img01, prod11img02, prod11img03, prod11img04] },
    { name: "Wall Art Nro2", size: "-", images: [prod14img01, prod14img02, prod14img03, prod14img04, prod14img05] },
    { name: "Franca", size: "-", images: [prod16img01, prod16img02, prod16img03] },
    { name: "African Stargate", size: "-", images: [prod15img01, prod15img02, prod15img03] },
    { name: "Everglades", size: "-", images: [prod17img01, prod17img02, prod17img03] },
    { name: "Eiffel", size: "-", images: [prod13img01, prod13img02, prod13img03, prod13img04] },
    { name: "Flamígera", size: "-", images: [prod18img01, prod18img02, prod18img03, prod18img04] },
];
