import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faFacebookF,
    faInstagram,
    // faTelegramPlane,
    faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { Link } from "react-router-dom";
import logo from "../../Images/logo-lg.png";
import estol from "../../Images/estol.png";

const FooterComponent = () => {
    return (
        <footer className="mt-auto text-light">
            <div className="container text-center">
                <div className="m-auto">
                    <h3 className="fs-1">Redes Sociales</h3>
                    <div className="social-links fs-1">
                        <a
                            className="mx-3 text-reset"
                            href="https://www.facebook.com/profile.php?id=61556879170484"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                        <a
                            className="mx-3 text-reset"
                            href="https://www.instagram.com/tiznado.uy/"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faInstagram} />
                        </a>
                        <a
                            className="mx-3 text-reset"
                            href="https://wa.link/13ze2y"
                            target="_blank"
                            rel="noreferrer"
                        >
                            <FontAwesomeIcon icon={faWhatsapp} />
                        </a>
                        {/* <a className="mx-2 text-reset" href="https://facebook.com" target="_blank" rel="noreferrer">
                                    <FontAwesomeIcon icon={faTelegramPlane} />
                                </a> */}
                    </div>
                </div>
                <div className="text-center text-dark mt-3">
                    <small>
                        Desarrollado por
                        <a href="https://estol.dev" target="_blank" rel="noreferrer"> 
                            {/* Esteban Olivera */}
                            <img src={estol} alt="Estol" style={{ height: "1.5rem" }} />
                        </a>
                        &copy;
                    </small>
                </div>
            </div>
        </footer>
    );
};

export default FooterComponent;
