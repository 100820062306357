import React, { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import logo from "../../Images/tiznado_logo.png";
import background from "../../Images/back.jpg";

const HeaderComponent = () => {
    const [show, setShow] = useState(false);
    const [transition, setTransition] = useState(false);
    const [hamburger, setHamburger] = useState("0deg");

    const handleClick = () => {
        if (window.innerWidth < 992 && show) {
            setHamburger(hamburger === "0deg" ? "90deg" : "0deg");
            setShow(!show);
            setTransition(true);
            setTimeout(() => {
                setTransition(false);
            }, 300);
        }
    };

    return (
        <nav className="navbar navbar-expand-lg navbar-dark shadow sticky-top p-0">
            <div className="container-fluid p-0">
                <Link className="navbar-brand m-2" to="/">
                    <img src={logo} alt="Bootstrap" id="brand-logo" />
                </Link>
                <button
                    className={`navbar-toggler m-2${show ? "" : " collapsed"}`}
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#navbarNav"
                    aria-controls="navbarNav"
                    aria-expanded={show ? "true" : "false"}
                    aria-label="Toggle navigation"
                    onClick={() => {
                        setHamburger(hamburger === "0deg" ? "90deg" : "0deg");
                        setShow(!show);
                        setTransition(true);
                        setTimeout(() => {
                            setTransition(false);
                        }, 300);
                    }}
                >
                    <span
                        className="navbar-toggler-icon"
                        style={{ transform: `rotate(${hamburger})`, transition: "all 200ms ease-in-out" }}
                    ></span>
                </button>
                <div
                    className={`navbar-collapse ${transition ? "collapsing" : "collapse"}${
                        show ? " show" : ""
                    }`}
                    id="navbarNav"
                >
                    <ul
                        className="navbar-nav ms-auto text-lg-end text-center"
                        // style={{ transition: "all 300ms ease-in-out" }}
                    >
                        <li className="nav-item">
                            <NavLink onClick={handleClick} className="nav-link" to="/">
                                Inicio
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink onClick={handleClick} className="nav-link" to="/disenos">
                                Diseños
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink onClick={handleClick} className="nav-link" to="/sobre-nosotros">
                                Sobre Nosotros
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink onClick={handleClick} className="nav-link" to="/contactanos">
                                Contáctanos
                            </NavLink>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default HeaderComponent;
