import { faAt, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Swal from "sweetalert2";
import { GoogleReCaptcha } from "react-google-recaptcha-v3";

const ContactComponent = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <>
            <Helmet>
                <title>Contáctanos | Tiznado | Piezas de autor</title>
                <meta
                    name="description"
                    content="¡Comienza tu viaje con Tiznado! Contáctanos para proyectos personalizados o consultas sobre nuestras piezas de autor en madera. Esperamos saber de ti pronto."
                />
                <link rel="canonical" href="https://tiznado.uy/contactanos" />
            </Helmet>
            <main id="contact">
                <div className="position-absolute top-0 w-100 h-100 d-flex justify-content-center align-items-center">
                    <div>
                        <h1 className="text-center">Contáctanos</h1>
                        {/* <div className="d-flex"> */}
                        <div className="text-center text-light p-5">
                            <h4>
                                <FontAwesomeIcon icon={faPhone} /> Teléfono
                            </h4>
                            <p>
                                <a href="tel:+59898878287" className="text-reset text-decoration-none">
                                    098 878 287
                                </a>
                            </p>
                            <h4>
                                <FontAwesomeIcon icon={faAt} /> Email
                            </h4>
                            <a href="mailto:contacto@tiznado.uy" className="text-reset text-decoration-none">
                                info@tiznado.uy
                            </a>
                        </div>
                    </div>
                </div>
            </main>
        </>
    );
};

export default ContactComponent;
