import React from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import HomeComponent from "./Components/Home/HomeComponent";
import HeaderComponent from "./Components/Layout/HeaderComponent";
import FooterComponent from "./Components/Layout/FooterComponent";
import DesignsComponent from "./Components/Designs/DesignsComponent";
import AboutComponent from "./Components/About/AboutComponent";
import ContactComponent from "./Components/Contact/ContactComponent";
import HomeComponentv2 from "./Components/Home/HomeComponentv2";

const Router = () => {
    return (
        <BrowserRouter>
            <HeaderComponent />
            <Routes>
                {/* <Route exact path="/" element={<HomeComponent />} /> */}
                <Route exact path="/" element={<HomeComponentv2 />} />
                <Route path="/disenos" element={<DesignsComponent />} />
                <Route path="/sobre-nosotros" element={<AboutComponent />} />
                    <Route path="/contactanos" element={<ContactComponent />} />
            </Routes>
            <FooterComponent />
        </BrowserRouter>
    );
};

export default Router;
