import React, { useEffect, useState } from "react";
import homeImg from "../../Images/home.jpg";
import { products } from "../../Images/Products/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleRight, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";

const HomeComponent = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [images, setImages] = useState();

    useEffect(() => {
        const productsImg = [...products];

        productsImg.sort(() => Math.random() - 0.5);
        productsImg.slice(0, 3);

        const imagesArr = [productsImg[0][0], productsImg[1][0], productsImg[2][0]];
        setImages(imagesArr);
    }, []);

    return (
        <>
            <Helmet>
                <title>Tiznado | Piezas de autor</title>
                <meta
                    name="description"
                    content="Explora el arte en madera único de Tiznado. Descubre piezas de autor que fusionan la belleza natural con la creatividad. ¡Transforma tu espacio hoy!"
                />
                <link rel="canonical" href="https://tiznado.uy" />
            </Helmet>
            <main id="home" className=" mt-3">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-8 order-md-0 order-1">
                            <div
                                className="rounded h-100 position-relative"
                                style={{
                                    backgroundImage: `url(${homeImg})`,
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    minHeight: "70vh",
                                }}
                            >
                                <div
                                    className="position-absolute top-0 start-0 end-0 bottom-0"
                                    style={{
                                        background:
                                            "linear-gradient(135deg, #f8f9fa 7%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, #f8f9fa 93%)",
                                    }}
                                />
                                <div
                                    className="position-absolute top-0 start-0 end-0 bottom-0"
                                    style={{
                                        background:
                                            "linear-gradient(225deg, #f8f9fa 7%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, #f8f9fa 93%)",
                                    }}
                                />
                                <div
                                    className="position-absolute top-0 start-0 end-0 bottom-0"
                                    style={{
                                        background:
                                            "linear-gradient(0deg, #f8f9fa 2%, rgba(0,0,0,0) 10%, rgba(0,0,0,0) 90%, #f8f9fa 98%)",
                                    }}
                                />
                                <div
                                    className="position-absolute top-0 start-0 end-0 bottom-0"
                                    style={{
                                        background:
                                            "linear-gradient(90deg, #f8f9fa 2%, rgba(0,0,0,0) 10%, rgba(0,0,0,0) 90%, #f8f9fa 98%)",
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-md-4">
                            <div className="h-100 text-center py-5 d-flex flex-column align-items-center justify-content-center">
                                <h1>TIZNADO</h1>
                                <h2>Piezas de autor</h2>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div className="container-fluid py-5">
                    <div className="row">
                        <div className="col-md-4">
                            <div className="h-100 d-flex justify-content-center align-items-center">
                                <div className="text-center">
                                    <h2>Belleza Hecha a Mano Para Interiores</h2>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div
                                className="rounded h-100 position-relative"
                                style={{
                                    backgroundImage: `url(${image3})`,
                                    backgroundPosition: "center",
                                    backgroundRepeat: "no-repeat",
                                    backgroundSize: "cover",
                                    minHeight: "80vh",
                                }}
                            >
                                <div
                                    className="position-absolute top-0 start-0 end-0 bottom-0"
                                    style={{
                                        background:
                                            "linear-gradient(225deg, #f8f9fa 14%, rgba(0,0,0,0) 50%, #f8f9fa 80%)",
                                    }}
                                />
                                <div
                                    className="position-absolute top-0 start-0 end-0 bottom-0"
                                    style={{
                                        background:
                                            "linear-gradient(0deg, #f8f9fa 5%, rgba(0,0,0,0) 20%, rgba(0,0,0,0) 80%, #f8f9fa 95%)",
                                    }}
                                />
                                <div
                                    className="position-absolute top-0 start-0 end-0 bottom-0"
                                    style={{
                                        background:
                                            "linear-gradient(90deg, #f8f9fa 2%, rgba(0,0,0,0) 10%, rgba(0,0,0,0) 90%, #f8f9fa 98%)",
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="container-fluid mt-3 py-5">
                    <h2>Nuestros Diseños</h2>
                    <hr />
                    <div className="row g-3 mx-4">
                        {images?.map((image, i) => (
                            <div className="col-md-3 py-3" key={i}>
                                <div className="rounded-3 bg-white shadow p-1 h-100 align-items-center d-flex position-relative border">
                                    <img src={image} alt="" className="img-fluid rounded-3" />
                                    <div className="img-filter-1 position-absolute top-0 w-100 h-100" />
                                    <div className="img-filter-2 position-absolute top-0 w-100 h-100" />
                                </div>
                            </div>
                        ))}
                        <div className="col-md-3 py-3">
                            <div className="d-flex justify-content-center align-items-center h-100">
                                <Link to={"/disenos"} className="btn btn-transparent">
                                    <FontAwesomeIcon icon={faArrowCircleRight} className="fs-1" />
                                    <span className="d-block fs-5">Ver todos</span>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid mt-3 py-5 text-center">
                    <h2 className="mb-4">¿Interesado en saber mas sobre nosotros y nuestros productos?</h2>
                    <h2 className="mb-4">Estaremos encantados de conversar contigo</h2>
                    <Link className="btn btn-dark btn-lg px-4" to={"/contactanos"}>
                        Contáctanos <FontAwesomeIcon icon={faArrowRight} />
                    </Link>
                </div>
            </main>
        </>
    );
};

export default HomeComponent;
